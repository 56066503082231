import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

export default function useAIAgentSessionEvents(sessionId) {

  const ref = sessionId
    ? Firebase.firestore().collection('boutiqAIAgentSessions').doc(sessionId).collection('boutiqAIAgentSessionEvents').orderBy('timestamp', 'asc')
    : null;
  const [events, eventsLoading, eventsError] = useCollection(ref);
  return { events: events?.docs ?? [], eventsLoading, eventsError }

}

