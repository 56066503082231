import React, { useState, useEffect } from 'react';
import { Card, Stack, DataTable, Modal, TextContainer, Button, Text, SkeletonBodyText } from '@shopify/polaris';
import { useShopProvider } from '../../../../components/ShopProvider';
import { currencyFormatter, numberFormatter, percentFormatter } from '../../../../utils/formatters';
import { useAIAgentSessionAggregates } from '../../../../hooks/useAIAgentSessions';
import { DashboardKPI } from '../../../Dashboard/DashboardHeader';
import "./styles.css";

import { useHistory } from 'react-router-dom';

export const AgentKPIObject = (currencyCode) => {
  return ({
      sessions_count: {
          name: 'Total Sessions ',
          value: 0,
          render: (value) => numberFormatter().format(value),
          isAgg: true,
          display: true,
          description: 'Total number of Boutiq agent sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      orders_total: {
          name: 'Total Sales',
          value: 0,
          render: (value) => currencyFormatter(currencyCode).format(value),
          isAgg: true,
          display: true,
          description: 'Total sales generated from Boutiq agent sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      orders_count: {
          name: 'Number of Orders',
          value: 0,
          render: (value) => numberFormatter().format(value),
          isAgg: true,
          display: true,
          description: 'Number of orders generated from Boutiq agent sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      conversion_rate: {
          name: 'Conversion Rate',
          value: 0,
          render: (value) => percentFormatter().format(value),
          display: true,
          description: 'ratio of Boutiq agent sessions resulting in a sale vs all Boutiq agent sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },      
      order_per_call: {
          name: 'Sales per Session',
          value: 0,
          render: (value) => currencyFormatter(currencyCode).format(value),
          display: true,
          description: 'Average sales generated on a Boutiq agent sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },
      average_order: {
          name: 'AOV',
          value: 0,
          render: (value) => currencyFormatter(currencyCode).format(value),
          display: true,
          description: 'Average Order Value for orders generated on Boutiq agent sessions',
          comparisonColor: (ratio) => ratio > 1.0 ? 'success' : ratio < 1.0 ? 'critical' : null,
      },      
  })
};

const AgentDashboard = ({ filter }) => {
  const { shopDataLoading, shopData, shopOrigin, } = useShopProvider();
  const history = useHistory();
  const { aggregates, loading } = useAIAgentSessionAggregates(shopOrigin, filter);
  const [kpis, setKpis] = useState(AgentKPIObject())

  useEffect(() => {
    if (shopData && aggregates) {
      const kpis = AgentKPIObject(shopData.currencyCode)
      kpis.sessions_count.value = aggregates.totalSessions
      kpis.orders_total.value = aggregates.totalOrderAmount
      kpis.orders_count.value = aggregates.totalOrders
      kpis.conversion_rate.value = aggregates.totalSessions ? (aggregates.totalOrders / aggregates.totalSessions) : null
      kpis.order_per_call.value = aggregates.totalSessions ? (aggregates.totalOrderAmount / aggregates.totalSessions) : null
      kpis.average_order.value = aggregates.totalOrders ? (aggregates.totalOrderAmount / aggregates.totalOrders) : null

      kpis.sessions_count.previousValue = aggregates.totalSessionsPrevious
      kpis.orders_total.previousValue = aggregates.totalOrderAmountPrevious
      kpis.orders_count.previousValue = aggregates.totalOrdersPrevious
      kpis.conversion_rate.previousValue = aggregates.totalSessionsPrevious ? (aggregates.totalOrdersPrevious / aggregates.totalSessionsPrevious) : null
      kpis.order_per_call.previousValue = aggregates.totalSessionsPrevious ? (aggregates.totalOrderAmountPrevious / aggregates.totalSessionsPrevious) : null
      kpis.average_order.previousValue = aggregates.totalOrdersPrevious ? (aggregates.totalOrderAmountPrevious / aggregates.totalOrdersPrevious) : null

      setKpis(kpis)
    }    
  }, [filter, aggregates,shopData])

  return (
    
      <Card>
        <div className='agent-dashboard-header'>
                {Object.keys(kpis).filter(kpi => kpis[kpi].display).map(kpi => <DashboardKPI key={kpi} kpi={kpis[kpi]} isLoading={loading || shopDataLoading} />)}
            </div>
      </Card>
  )
}

export default AgentDashboard;
