import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';

import Moment from 'moment-timezone';
import { Stack, Layout, Card, Spinner, Button, TextStyle, TextContainer, Heading, ButtonGroup, Modal, Banner} from '@shopify/polaris';

import AdminPage from '../../components/AdminPage';
import AppPage from '../../components/AppPage';
import AppWarnings from '../../components/AppWarnings';
import { useShopProvider } from '../../components/ShopProvider';
import { isInBusinessHours } from '../../components/BusinessHours';
import useHostAvailability from '../../hooks/useHostAvailability';
import useHostSchedulingConfig from '../../hooks/useHostSchedulingConfig';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import SelectHost from '../../components/SelectHost';
import useShopUsers from '../../hooks/useShopUsers';
import CaazamAvailability from './availability';
import CaazamConfirmCustomHours from './confirmCustomHoursDialog';
import { HostConfig } from './consts';
import {HOST_AVAILABILITY} from "../../constants/const";
import useHostAvailabilityActions from "../../hooks/useHostAvailabilityActions";
import {availabilityColorByType} from "../../utils/colors";
import {CallRoutePolicy} from "../../components/CallRoutingPolicyConfig";

const localizer = momentLocalizer(Moment);

const LEGEND_ITEMS = (isCustomCallPolicy) =>
  [
    {
      label: isCustomCallPolicy ? 'Scheduled calls' : 'Host Availability',
      type: 'window',
      visible: true
    },
    {
      label: 'Instant calls',
      type: 'instant',
      visible: isCustomCallPolicy
    }
  ]

function HostCalendar() {
  const history = useHistory();
  const location = useLocation();

  const { hostId: defaultHostId } = useParams();
  const [events, setEvents] = useState([]);
  const [selected, setSelected] = useState(null);
  const [currentRange, setCurrentRange] = useState({
    start: Moment().startOf('week').toDate(),
    end: Moment().endOf('week').toDate(),
  });

  const [eventWindowOpen, setWindowModalOpen] = useState(false);
  const [hostConfig, setHostConfig] = useState(HostConfig.Default);
  const { shopOrigin, businessHoursConfig, businessHoursLoading, callPolicyConfig } = useShopProvider();

  const [hostAvailabilityWindows, setHostAvailabilityWindows] = useState([])
  const [hostAvailabilityLoading, setHostAvailabilityLoading] = useState(false)
  const [hostAvailabilityType, setHostAvailabilityType] = useState(HOST_AVAILABILITY.EVENTS)

  const [selectedDate, setSelectedDate] = useState(Moment())

  const isCustomCallPolicy = useMemo(() => (
    callPolicyConfig && callPolicyConfig.callRoutingPolicy.type === CallRoutePolicy.custom
  ), [callPolicyConfig])

  const {
    availabilityWindows: hostEventsWindows,
    loading: hostEventsLoading
  } = useHostAvailability(
    shopOrigin, currentRange, HOST_AVAILABILITY.EVENTS
  );
  const {
    availabilityWindows: hostCallsWindows,
    loading: hostCallsLoading
  } = useHostAvailability(
    shopOrigin, currentRange, HOST_AVAILABILITY.CALLS, isCustomCallPolicy && hostConfig === HostConfig.Custom
  );

  const {
    addHostAvailability,
    deleteHostAvailability,
    updateHostAvailability,
    updateRecurrenceRule,
    actionLoading
  } = useHostAvailabilityActions(
    shopOrigin, hostAvailabilityType, hostAvailabilityWindows.map(({databaseEvent}) => databaseEvent)
  );

  const { hosts } = useShopUsers(shopOrigin);
  const [host, setHost] = useState(defaultHostId);
  const hostData = hosts.find(h => h.id === host);
  const teamId = (hostData && hostData.teamId) || null;

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(null);
  const [defaultHoursWarning, setDefaultHoursWarning] = useState(null);
  const {
    getHostConfig,
    hostSchedulingConfigLoading,
    setDefaultBusinessHours,
    removeHours,
  } = useHostSchedulingConfig(shopOrigin);
  const hostSchedulingConfig = getHostConfig(host);

  const mapWindow = (window) => {
    const titleCustomTitle = window.type === HOST_AVAILABILITY.EVENTS ? 'Scheduled Calls' : 'Instant Calls'
    return {
      id: window.eventId,
      start: window.windowStart,
      end: window.windowEnd,
      resource: window.hostId,
      allDay: false,
      isRecurring: window.isRecurring,
      recurrenceEnd: window.recurrenceEnd,
      title: isCustomCallPolicy ? titleCustomTitle : 'Available',
      type: window.type,
    }
  }

  useEffect(() => {
    const event = events?.find(event => event.id === location?.state?.eventId)
    if (location?.state?.eventId) {
      if (event) {
        setWindowModalOpen(true);
        clearEvents();
        history.replace();
        setSelected(event);
      } else {
        setSelectedDate(location?.state?.start)
        setCurrentRange({
          start: Moment(location?.state?.start).startOf('week').toDate(),
          end: Moment(location?.state?.end).endOf('week').toDate()
        })
      }
    }
  }, [location?.state?.eventId, events.length])

  useEffect(() => {
    setHostAvailabilityWindows([
      ...hostEventsWindows,
      ...hostCallsWindows
    ])
  }, [JSON.stringify(hostEventsWindows), JSON.stringify(hostCallsWindows)])

  useEffect(() => {
    setHostAvailabilityLoading(hostEventsLoading && hostCallsLoading)
  }, [hostEventsLoading, hostCallsLoading])

  useEffect(() => {
    if (selected) {
      setHostAvailabilityType(selected.type)
    } else {
      setHostAvailabilityType(HOST_AVAILABILITY.EVENTS)
    }
  }, [selected])

  useEffect(() => {
    if (hostAvailabilityWindows.length) {
      setEvents(hostAvailabilityWindows.map(mapWindow))
    }
  }, [JSON.stringify(hostAvailabilityWindows)]);

  useEffect(() => {
    if (!hostSchedulingConfigLoading) {
      if(!hostSchedulingConfig) {
        setHostConfig(HostConfig.None)
      } else {
        setHostConfig(hostSchedulingConfig?.defaultBusinessHours ? HostConfig.Default : HostConfig.Custom);
      }
    }
  }, [hostSchedulingConfigLoading,hostSchedulingConfig ]);

  const onRangeChange = (range) => {
    if (Array.isArray(range)) {
      const datesCount = range.length;
      if (datesCount === 1)
        setCurrentRange({ start: Moment(range[0]).toDate(), end: Moment(range[0]).endOf('day').toDate() });
      else
        setCurrentRange({ start: Moment(range[0]).toDate(), end: Moment(range[datesCount - 1]).toDate() });
    } else {
      let startMoment = Moment(range.start), endMoment = Moment(range.end);
      setCurrentRange({ start: startMoment.toDate(), end: endMoment.toDate() });      
    }
  }

  function Event({ event }) {
    return (
      <span>
        <strong>{event.title}</strong>
      </span>
    )
  }

  function EventWrapper({ event, children }) {
    const isNewEvent = !event.id
    return (
      <div className={`custom-event-wrapper-container blue`} style={{
        '--event-color': availabilityColorByType(isNewEvent ? hostAvailabilityType : event.type)
      }}>
        {children}
      </div>
    )
  }

  function eventPropGetter(data) {
      return { className: 'rbc-event-available-window' };
  }

  const slotPropGetter = useCallback((slot) => {
    const open = isInBusinessHours(businessHoursConfig, slot);
    if (!open) {
      return { className: 'slot-shop-closed' };
    } else {
      return null;
    }
  }, [businessHoursConfig]);

  const onSelectEvent = (data, target) => {
    if (hostConfig === HostConfig.Custom) {
        setWindowModalOpen(true);
        clearEvents();
        setSelected(data);
    } else {
      setDefaultHoursWarning(hostConfig);
    }      
  }

  
  const handleNewEvent = (e) => {
    if (hostConfig === HostConfig.Custom) {
      clearEvents();

      if (e.action === 'click' && eventWindowOpen) {
        setWindowModalOpen(false);
        setSelected(null);
      }

      if (e.action !== 'click') {
        const newEvent = {
          start: e.start,
          end: e.end,
          title: 'Availability',
          id: e.id,
          resource: e.resource ? e.resource : host,
          forcePopup: false,
          type: HOST_AVAILABILITY.EVENTS
        }
        if (e.action === 'select') {
          newEvent.forcePopup = true;
        }
        setSelected(newEvent);
        setWindowModalOpen(true);
        setEvents([...events, newEvent])
      }
    } else {
      setDefaultHoursWarning(hostConfig);
    }
  }

  const clearEvents = () => {
    setEvents(existed => existed.filter(({id}) => id));
  }

  const durationCalculation = (testSlot) => {
    let start = testSlot.start;
    let end = testSlot.end;

    if (testSlot.startTime) {
      start = Moment(testSlot.startTime).toDate();
    }
    if (testSlot.endTime) {
      end = Moment(testSlot.endTime).toDate();
    }
    const duration = Moment(end).diff(Moment(start), 'minutes');
    return { start, duration }
  }

  const addWindow = async (testSlot) => {
    if (testSlot) {
      const { start, duration } = durationCalculation(testSlot);
      try {
        let recurrence = null;
        if (testSlot.repeat_mode) {
          recurrence = { ...recurrence, isRecurrence: true }
          if (testSlot.repeat_end) {
            recurrence = { ...recurrence, endOn: Moment(testSlot.repeat_end_date).toDate() }
          }
        }
        await addHostAvailability(start, duration, host, teamId ,recurrence);
        setWindowModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const deleteWindow = async (testSlot, deleteAllFuture) => {
    if (testSlot) {
      try {
        await deleteHostAvailability(testSlot.id, testSlot.start, deleteAllFuture);
        setWindowModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const updateWindow = async (testSlot, allFuture) => {
    if (testSlot) {
      try {
        const { start, duration } = durationCalculation(testSlot);
        await updateHostAvailability(testSlot.id, testSlot.start, start, duration, allFuture);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const updateWindowRecurrenceRule = async (testSlot) => {
    if (testSlot) {
      try {
        let recurrence = null;
        if (testSlot.repeat_mode) {
          recurrence = { ...recurrence, isRecurrence: true }
          if (testSlot.repeat_end) {
            recurrence = { ...recurrence, endOn: Moment(testSlot.repeat_end_date).toDate() }
          }
        }
        await updateRecurrenceRule(testSlot.id, recurrence);
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleDelete = async (deleteAllFuture = true) => {
    await deleteWindow(selected, deleteAllFuture);
  }

  const onHostConfigChange = (newHostConfig) => {
    if (newHostConfig === hostConfig) return;
    setConfirmDialogOpen(newHostConfig);
  }

  return (
    <AppPage
      title='Host calendar settings'
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
      fullWidth={true}
    >
      <Layout>
        <Layout.Section>
          <AppWarnings />
        </Layout.Section>
        <Layout.Section>
          <div style={{ padding: '0 1rem' }}>
            <TextContainer spacing='tight'>
              <p><TextStyle variation='subdued'>Manage a host's availability hours.</TextStyle></p>
            </TextContainer>
          </div>
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <div style={{ display: 'flex', flexFlow: 'column', height: 'calc(100vh - 250px)' }}>
              <Calendar
                localizer={localizer}
                selectable={true}
                selected={selected}
                events={events.filter(event => event.resource === host)}
                showMultiDayTimes={true}
                endAccessor={({ end }) => new Date(end.getTime() - 1)}
                views={[Views.WEEK, Views.DAY]}
                defaultView={Views.WEEK}
                scrollToTime={new Date(2000, 1, 1, 8)}
                onRangeChange={onRangeChange}
                eventPropGetter={eventPropGetter}
                slotPropGetter={slotPropGetter}
                allDayAccessor={() => false}
                date={selectedDate}
                onNavigate={setSelectedDate}
                components={{
                  event: Event,
                  eventWrapper: EventWrapper,
                  toolbar: (e) => (
                    <div className='custom-toolbar-container'>
                      <Stack distribution='fill'>
                        <Stack vertical>
                          <Heading>{e.label}</Heading>
                          <Stack distribution='equalSpacing'>
                            <ButtonGroup segmented>
                              {e.views.map((item, index) => (
                                <Button
                                  key={index}
                                  pressed={e.view === item}
                                  onClick={() => e.onView(item)}>
                                  {item}
                                </Button>
                              ))}
                            </ButtonGroup>
                            <Stack alignment='center'>
                              <SelectHost
                                onHostChange={setHost}
                                hosts={hosts}
                                selecetedHost={host}
                                allowAll={false}
                              />
                              {(businessHoursLoading || hostAvailabilityLoading || hostSchedulingConfigLoading) && <Spinner size='small' />}
                            </Stack>
                            <ButtonGroup segmented>
                              <Button onClick={() => e.onNavigate('PREV')}>Back</Button>
                              <Button onClick={() => e.onNavigate('TODAY')}>Today</Button>
                              <Button onClick={() => e.onNavigate('NEXT')}>Next</Button>
                            </ButtonGroup>
                          </Stack>
                          <div className='host-calendar-controls'>
                            <Stack distribution='trailing'>
                              <Stack alignment='center'>
                                <p>Availability</p>
                                <ButtonGroup segmented>
                                  <Button
                                    outline={hostConfig !== HostConfig.Default}
                                    primary={hostConfig === HostConfig.Default}
                                    onClick={() => onHostConfigChange(HostConfig.Default)}
                                    disabled={hostSchedulingConfigLoading}
                                  >
                                    Default
                                  </Button>
                                  <Button
                                    outline={hostConfig !== HostConfig.Custom}
                                    primary={hostConfig === HostConfig.Custom}
                                    onClick={() => onHostConfigChange(HostConfig.Custom)}
                                    disabled={hostSchedulingConfigLoading}
                                  >
                                    Custom
                                  </Button>
                                  <Button
                                    outline={hostConfig !== HostConfig.None}
                                    primary={hostConfig === HostConfig.None}
                                    destructive={hostConfig === HostConfig.None}
                                    onClick={() => onHostConfigChange(HostConfig.None)}
                                    disabled={hostSchedulingConfigLoading}
                                  >
                                    None
                                  </Button>
                                </ButtonGroup>
                              </Stack>
                            </Stack>
                          </div>
                        </Stack>
                        {e.children}
                      </Stack>
                    </div>
                  )
                }}
                onSelectEvent={onSelectEvent}
                onSelectSlot={handleNewEvent}
              />
            </div>
            <div className='legend-container'>
              <Stack>
                {LEGEND_ITEMS(isCustomCallPolicy && hostConfig === HostConfig.Custom).map((item, index) => (
                  item.visible && (
                    <Stack wrap={false} key={index}  >
                      <div className={`legend-icon ${item.type}`}>
                      </div>
                      <p> — {item.label}</p>
                    </Stack>
                  )
                ))}
              </Stack>
            </div>
          </Card>
        </Layout.Section>
      </Layout>
      <CaazamAvailability
        open={eventWindowOpen}
        onClose={() => { setWindowModalOpen(prev => !prev); clearEvents(); }}
        data={selected}
        add={addWindow}
        update={updateWindow}
        updateWindowRecurrenceRule={updateWindowRecurrenceRule}
        handleDelete={handleDelete}
        isCustomCallPolicy={isCustomCallPolicy && hostConfig === HostConfig.Custom}
        onChangeHostAvailabilityType={setHostAvailabilityType}
        selectedHostAvailabilityType={hostAvailabilityType}
        actionLoading={actionLoading}
      />
      <CaazamConfirmCustomHours
        open={!!confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(null)}
        hostConfig={hostConfig}
        newHostConfig={confirmDialogOpen}
        setDefaultBusinessHours={(val) => setDefaultBusinessHours(host, val)}
        removeHours={()=> removeHours(host)}
         />
      <Modal
        open={!!defaultHoursWarning}
        onClose={()=>setDefaultHoursWarning(null)}
        title={'warning'}
        titleHidden={true}        
      >
        <Modal.Section>
          <Stack>
            <Banner
              title='Cannot edit host availability'
              status='warning'
              action={{content: 'Change to custom availability', onAction: () => {
                onHostConfigChange(HostConfig.Custom);
                setDefaultHoursWarning(null);
              }}}
            >
              <p>
                To edit a host's availability please set them to custom availability.
              </p>
            </Banner>
          </Stack>
        </Modal.Section>

      </Modal>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <HostCalendar />
    </AdminPage>
  )
}
