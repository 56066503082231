import { useCollection } from 'react-firebase-hooks/firestore';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useEffect, useState } from 'react';
import { getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
import { httpsCallable, getFunctions } from 'firebase/functions';
import app from '../Firebase';
import { formatUploadFilename } from '../utils/formatters';
import { useAppAuthState } from '../authState';

export default function useShopUsers(shopId) {
    const { user: currentUser } = useAppAuthState();
    const ref = shopId ? Firebase.firestore().collection('shops').doc(shopId).collection('shopUsers') : null;
    const [query, usersLoading, usersError] = useCollection(ref);
    const [users, setUsers] = useState([]);
    const [hosts, setHosts] = useState([]);

    useEffect(() => {
        if (query) {
            setUsers(query.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        } else {
            setUsers([]);
        }
    }, [query]);

    useEffect(() => {
        if (users) {
            setHosts(users.filter(user => user.roles.includes('host')).sort((a, b) => {
                return (a.automated === true ? 1 : 0) - (b.automated === true ? 1 : 0);
            }));
        } else {
            setHosts([]);
        }
    }, [users])

    const updateHostHiddenState = (config) => {
        if (!ref) return;
        const batch = Firebase.firestore().batch();
        config.map(host => batch.update(ref.doc(host.id), {
            isHidden: host.isHidden ? true : Firebase.firestore.FieldValue.delete()
        }));
        return batch.commit();
    }

    const updateHostBio = (id, bio) => {
        if (!ref) return;
        return ref.doc(id).update({ bio });
    }

    async function updateHostAvatar(hostId, avatarFile) {
        if (avatarFile && hostId) {

            const storage = getStorage(app, process.env.REACT_APP_AVATARS_BUCKET);
            const filePath = `users/${hostId}/${formatUploadFilename(avatarFile.name)}`;
            const fileRef = storageRef(storage, filePath);
            await uploadBytes(fileRef, avatarFile, {
                customMetadata: {
                    shopId: shopId,
                    uploadedBy: currentUser.uid,
                },
            });

            const functions = getFunctions(app);
            const request = httpsCallable(functions, 'users-processUserAvatar');
            await request({
                fileBucket: fileRef.bucket,
                filePath: fileRef.fullPath,
                shopId,
                userId: hostId,
            });
        }
    }

    return { users, hosts, usersLoading, usersError, updateHostHiddenState, updateHostBio, updateHostAvatar }
}
