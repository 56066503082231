import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useShopProvider } from '../../components/ShopProvider';

export default function AuthorizedAgentRoute({ component: Component, redirectTo = '/not-authorized', ...rest }) {
  const { shopData } = useShopProvider();
  const supportsBoutiqAIAgent = shopData?.productPolicy?.supportsBoutiqAIAgent;

  if (!shopData) {
    return null;
  }

  return supportsBoutiqAIAgent ? (
    <Route {...rest} component={Component} />
  ) : (
    <Redirect to={redirectTo} />
  );
}

