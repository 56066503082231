import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

export default function useAIAgentSessionMetadata(sessionId) {

  const ref = sessionId
    ? Firebase.firestore().collection('boutiqAIAgentSessions').doc(sessionId).collection('boutiqAIAgentSessionMetadata')
    : null;
  const [metadata, metadataLoading, metadataError] = useCollection(ref);
  return { metadata: metadata?.docs ?? [], metadataLoading, metadataError }

}

