import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  getFirestore,
  setDoc,
  doc,
} from 'firebase/firestore';

export default function useShopAgentConfig(shopId) {
  const ref = shopId ? doc(getFirestore(), `shops/${shopId}/installData/boutiqAI`) : null;
  const [boutiqAIConfig, boutiqAIConfigLoading, boutiqAIConfigError] = useDocumentData(ref);

  async function setBoutiqAIConfig(config) {
    await setDoc(ref, config);
  }

  return { boutiqAIConfig, boutiqAIConfigLoading, boutiqAIConfigError, setBoutiqAIConfig }
}

