import React, { useState, useEffect } from 'react';
import { Card, Stack, DataTable, Text, SkeletonBodyText } from '@shopify/polaris';
import { RefreshMajor } from '@shopify/polaris-icons';
import sessionDataTableRow from '../../../../utils/agentSessions';
import useAIAgentSessions from '../../../../hooks/useAIAgentSessions';
import { useAppAuthState } from '../../../../authState';

import CallHistoryTablePagination from '../../../CallHistory/components/CallHistoryTablePagination';

import { useHistory } from 'react-router-dom';

const ROWS_PER_PAGE = 15;
const CONTENT_TYPES = ['text', 'text', 'text', 'text'];
const HEADERS = () => [
  'Date',
  'Client Data',
  'Stats',
  'Flagged',
  'Sales Amount',
]

const AgentSessionsList = ({ filter }) => {
  const history = useHistory();
  const { shopOrigin } = useAppAuthState();
  const [currentPage, setCurrentPage] = useState(0);


  const {
    sessions: sessionsDocs,
    refresh: refreshSessions,
    getMoreSessions,
    loading,
  } = useAIAgentSessions(
    shopOrigin,
    [],
    ROWS_PER_PAGE,
    filter
  );

  useEffect(() => {
    refreshTable()
  }, [filter])

  const sessions = sessionsDocs.map(doc => ({
    ...doc.data(),
    id: doc.id
  }))

  const refreshTable = () => {
    refreshSessions();
    setCurrentPage(0);
  }

  const handlerOnChangePage = (page) => {
    if ((page + 1) * ROWS_PER_PAGE >= sessions.length) {
      getMoreSessions();
    }
    setCurrentPage(page);
  }

  const onRowClick = (sessionData) => {
    if (sessionData) {
      history.push(`/boutiq-ai/agent/session/${sessionData.id}`)
    }
  }

  const mapSessionToTable = (sessionData) => {
    const { row } = sessionDataTableRow({
      sessionData,
      onRowClick,
    })
    return row
  }

  const currentSessions = sessions.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE).map(mapSessionToTable);
  const hasNext = (sessions.length > (currentPage + 1) * ROWS_PER_PAGE);

  const footerContent = () => {
    if (sessions.length > 0) {
      return (
        <CallHistoryTablePagination
          currentPage={currentPage}
          hasNext={hasNext}
          itemsPerPage={ROWS_PER_PAGE}
          onChangePage={handlerOnChangePage}
          noun='sessions'
        />
      )
    }
    return (
      loading ? (
        <SkeletonBodyText lines={4} />
      ) : (
        <Text>{'No agent sessions found'}</Text>
      )
    )
  }

  return (
    <div
      onClick={(e) => {
        const index = e.target.parentElement.rowIndex
        if (index) {
          onRowClick(sessions[index - 1])
        }
      }}
    >
      <Card
        title="Session list"
      >
        <Stack vertical>
          <DataTable
            columnContentTypes={CONTENT_TYPES}
            headings={HEADERS()}
            rows={currentSessions}
            footerContent={footerContent()}
          />
        </Stack>
      </Card>
    </div>
  )
}

export default AgentSessionsList;
