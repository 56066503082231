import React from 'react'
import { useHistory } from 'react-router-dom';

import { Card, EmptyState, Text, Avatar, Stack, Button, Badge, SkeletonBodyText } from '@shopify/polaris';
import { useShopProvider } from '../../../components/ShopProvider';
import useHostSchedulingConfig from '../../../hooks/useHostSchedulingConfig';


export function HostItem({ teams, host, status }) {
    const history = useHistory();

    const defineTeamName = (id) => {
        if (!id) return null;
        const team = teams.find(item => item.id === id);
        if (team) {
            return team.name;
        } else {
            return null;
        }
    }

    const isDefault = status && status.defaultBusinessHours;
    const isCustom = status && !status.defaultBusinessHours;
    const isNoHours = !status;

    return (
        <div style={{ paddingBottom: '0.8rem' }}>
            <Stack>
                <Avatar source={host.avatarUrl ? host.avatarUrl : null} name={host.firstName} initials={`${host.firstName && host.firstName[0]}${host.lastName && host.lastName[0]}`} />
                <Stack.Item fill>
                    <Stack distribution='leading'>
                        <Stack.Item fill>
                            <div>
                                <h3>
                                    <Stack>
                                        <Text fontWeight='bold'>{host.firstName} {host.lastName}</Text>
                                        {host.teamId &&
                                            <Badge>
                                                <p style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '14ch'
                                                }}>
                                                    {defineTeamName(host.teamId)}
                                                </p>
                                            </Badge>}
                                    </Stack>
                                    {host.email && <Text variant='bodySm'>{host.email}</Text>}
                                    {host.automated && <Text variant='bodySm' color='subdued'>disabled for scheduling</Text>}
                                </h3>
                            </div>
                        </Stack.Item>
                        <div style={{ marginRight: '32px' }}>
                            {isDefault && <Badge status="new">default business hours</Badge>}
                            {isCustom && <Badge status="info" >custom hours</Badge>}
                            {isNoHours && <Badge status='critical' >none</Badge>}
                        </div>
                        <Button plain onClick={() => history.push(`/calendar/hosts/${host.id}`)} disabled={host.automated}>change</Button>
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    )
}

export default function HostsTable({ }) {
    const history = useHistory();
    const {
        shopOrigin,
        hosts, usersLoading,
        invites,
        shopUserTeams
    } = useShopProvider();

    const {
        getHostConfig,
        hostSchedulingConfigLoading,
    } = useHostSchedulingConfig(shopOrigin);

    const inviteHostAction = { content: 'Invite a host', onAction: () => history.push('/inviteHost') }

    const emptyStateMarkup = (
        <EmptyState
            heading="No hosts"
            action={inviteHostAction}>
            <p>
                Hosts are your sales associates that would respond to scheduled video calls
            </p>
        </EmptyState>
    );

    if (usersLoading || hostSchedulingConfigLoading) {
        return (<Card sectioned><SkeletonBodyText /></Card>)
    }

    return (
        <Card sectioned>
            {hosts.length === 0 && invites.length === 0 && emptyStateMarkup}
            {hosts.map((host, index) => (
                <HostItem teams={shopUserTeams} host={host} key={host.id} status={getHostConfig(host.id)} />
            ))}
        </Card>
    )
}
