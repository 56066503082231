import React, { useEffect, useMemo, useState } from 'react';
import {
    Card,
    Stack,
    Text,
    SkeletonBodyText,
    Banner,
    Avatar,
    Box,
} from '@shopify/polaris';
import BoutiqAvatar from '../../../../components/Avatar';
import useAIAgentSessionMessages from '../../../../hooks/useAIAgentSessionMessages';
import useAIAgentSessionEvents from '../../../../hooks/useAIAgentSessionEvents';
import { AgentMessageRole } from '../../../../utils/agentSessions';
import './styles.css';


function getInitials(displayName) {
    if (!displayName) return 'C';
    return displayName.split(' ').map(w => w?.[0] ?? '').slice(0, 2).join('').toUpperCase();
}


function timeFormatter(time) {
    const timeInSec = parseInt(time);
    var hours = Math.floor(timeInSec / 3600);
    var minutes = Math.floor((timeInSec - (hours * 3600)) / 60);
    var seconds = timeInSec - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
}

function compareProductsList(message, prevMessage) {
    if (!message?.metadata?.recommended_products) return false;
    if (!prevMessage?.metadata?.recommended_products) return true;

    const currentProducts = message.metadata.recommended_products.map(p => p.variant_id).sort().join(',');
    const prevProducts = prevMessage.metadata.recommended_products.map(p => p.variant_id).sort().join(',');

    return currentProducts !== prevProducts;
}

function getPreviousMetadataMessage(messages, index) {
    for (let i = index - 1; i >= 0; i--) {
        if (messages[i].data().metadata) {
            return messages[i].data();
        }
    }
    return null;
}

export default function MessagesView({ sessionId, sessionData, customerName, host }) {
    const { messages, messagesLoading, messagesError } = useAIAgentSessionMessages(sessionId);
    const { events, eventsLoading, eventsError } = useAIAgentSessionEvents(sessionId);
    const items = useMemo(() => {
        const combinedItems = messages.map((message, index) => ({
            id: message.id,
            ...message.data(),
            showProducts: compareProductsList(message.data(), getPreviousMetadataMessage(messages, index)),
            itemType: 'message',
        })).concat(events.map(event => ({
            id: event.id,
            ...event.data(),
            itemType: 'event',
        })));

        return combinedItems.sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis());
    }, [messages, events]);

    const productMetadata = useMemo(() => {
        return messages.reduce((acc, message) => {
            if (message.data().metadata?.product_metadata) {
                Object.entries(message.data().metadata.product_metadata).forEach(([key, value]) => {
                    if (!acc[key]) {
                        acc[key] = value;
                    }
                });
            }
            return acc;
        }, {});
    }, [messages]);

    return (
        <Card title='Session chat transcript' sectioned>
            <Card.Section>
                <Stack vertical>
                    {messagesLoading && <SkeletonBodyText />}
                    {!messagesLoading &&
                        <Stack vertical>
                            {items.map(item => {
                                if (item.itemType === 'message') {
                                    return <AgentChatMessage
                                        key={item.id}
                                        message={item}
                                        showProducts={item.showProducts}
                                        sessionData={sessionData}
                                        customerName={customerName}
                                        host={host}
                                    />
                                } else if (item.itemType === 'event') {
                                    return <AgentChatEvent
                                        key={item.id}
                                        event={item}
                                        host={host}
                                        customerName={customerName}
                                        sessionData={sessionData}
                                        productMetadata={productMetadata}
                                    />
                                }
                            })}
                        </Stack>
                    }
                </Stack>
            </Card.Section>
        </Card>
    )
}

function AgentChatMessage({ message, sessionData, customerName, host, showProducts }) {
    const avatarUrl = host?.avatarUrl;
    const customerInitials = getInitials(customerName);
    const messageOffset = (message.timestamp.toDate() - sessionData.createdAt.toDate()) / 1000;
    const isAgent = message.role !== AgentMessageRole.user;

    return (
        <Box
            style={{
                display: 'grid',
                gridTemplateColumns: isAgent ? '70% 50px' : '50px 70%',
                justifyContent: isAgent ? 'end' : 'start',
                justifyItems: isAgent ? 'end' : 'start',
            }}
        >
            {!isAgent && (
                <Box>
                    <Avatar name={customerName} initials={customerInitials} />
                </Box>
            )}
            <Stack distribution={isAgent && 'trailing'} alignment='leading'>
                <Stack vertical spacing='extraTight'>
                    <Text alignment={isAgent && 'end'} color='subdued' variant='bodySm'>{timeFormatter(messageOffset)}</Text>
                    <Text alignment={isAgent && 'end'} color={message.metadata?.state === "FINISH" ? 'subdued' : null}>{message.content}</Text>
                    {message.metadata?.recommended_products && showProducts &&
                        <MessageProductsList
                            metadata={message.metadata}
                        />
                    }
                </Stack>
            </Stack>
            {isAgent && (
                <Box>
                    {isAgent
                        ? <BoutiqAvatar
                            source={avatarUrl ?? null}
                            name={sessionData.config.botName}
                            initials={getInitials(sessionData.config.botName)}
                            size="medium"
                        />
                        : <Avatar name={customerName} initials={customerInitials} size='extraSmall' />
                    }
                </Box>
            )}
        </Box>
    )
}

function AgentChatEvent({ event, host, sessionData, customerName, productMetadata }) {
    const avatarUrl = host?.avatarUrl;
    const customerInitials = getInitials(customerName);
    const messageOffset = (event.timestamp.toDate() - sessionData.createdAt.toDate()) / 1000;
    const isAgent = ['end_conversation'].includes(event.type);

    return (
        <Box
            style={{
                display: 'grid',
                gridTemplateColumns: isAgent ? '70% 50px' : '50px 70%',
                justifyContent: isAgent ? 'end' : 'start',
                justifyItems: isAgent ? 'end' : 'start',
            }}
        >
            {!isAgent && (
                <Box>
                    <Avatar name={customerName} initials={customerInitials} />
                </Box>
            )}
            <Stack distribution={isAgent && 'trailing'} alignment='leading'>
                <Stack vertical spacing='extraTight'>
                    <Text alignment={isAgent && 'end'} color='subdued' variant='bodySm'>{timeFormatter(messageOffset)}</Text>
                    {event.type === 'addToCart' &&
                        <EventAddToCart event={event} productMetadata={productMetadata} />
                    }
                </Stack>
            </Stack>
            {isAgent && (
                <Box>
                    {isAgent
                        ? <BoutiqAvatar
                            source={avatarUrl ?? null}
                            name={sessionData.config.botName}
                            initials={getInitials(sessionData.config.botName)}
                            size="medium"
                        />
                        : <Avatar name={customerName} initials={customerInitials} size='extraSmall' />
                    }
                </Box>
            )}
        </Box>
    )
}

function MessageProductsList({ metadata }) {
    if (!metadata.recommended_products) return null;

    return (
        <Banner title="Products recommended">
            <Stack distribution='trailing'>
                {metadata.recommended_products.map(product => {
                    const { product_id, product_title, variant_id, variant_title } = product;
                    const image = metadata.product_metadata[product_id]?.variants.nodes.find(v => v.id === variant_id)?.image?.thumbnail ?? null;
                    return <BoutiqProductCard key={variant_id} productTitle={product_title} variantId={variant_id} variantTitle={variant_title} image={image} />
                })}
            </Stack>
        </Banner>
    )
}

function EventAddToCart({ event, productMetadata }) {
    const { product_id, product_title, variant_id, variant_title } = event.product;
    const image = productMetadata?.[product_id]?.variants.nodes.find(v => v.id === variant_id)?.image?.thumbnail ?? null;

    return (
        <Banner title="Product added to cart" status='success'>
            <Stack distribution='trailing'>
                <BoutiqProductCard productId={product_id} productTitle={product_title} variantId={variant_id} variantTitle={variant_title} image={image} />
            </Stack>
        </Banner>
    )
}

function BoutiqProductCard({ productId, productTitle, variantId, variantTitle, image }) {

    return (
        <div className='boutiq-product-card'
            key={productId}
        >
            <img
                src={image}
                alt={productTitle + ' / ' + variantTitle}
            />
            <Text variant='bodySm'>{productTitle + ' / ' + variantTitle}</Text>

        </div>)
}