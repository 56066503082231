
const pathRegex = /^((\/[a-zA-Z0-9-._~!$&'()*+,;=:@]+)+|\/)$/;

export function isValidPath(str) {
    return pathRegex.test(str);
}

export function isValidUrl(str) {
    try {
        new URL(str);
        return true;
    } catch (err) {
        return false;
    }
}
