import React, { useState } from "react";

import {
  Banner,
  Layout,
  Text,
  Stack,
  Button
} from '@shopify/polaris';
import { RefreshMajor } from "@shopify/polaris-icons";
import { AdjustMinor } from "@shopify/polaris-icons";
import { useHistory, Link } from "react-router-dom";

import AdminPage from "../../../components/AdminPage";
import AppPage from "../../../components/AppPage";
import AgentSessionList from "./AgentSessionList";
import AgentDashboard from "./AgentDashboard";
import DateRangeSelector from '../../../components/Selectors/DateRangeSelector';
import { useShopProvider } from "../../../components/ShopProvider";
import useShopAgentConfig from "../../../hooks/useShopAgentConfig";
import { DateRange } from '../../Dashboard/useDateRange';

const defaultFilter = {
  range: DateRange.last30,
}

const BoutiqAIAgent = () => {
  const history = useHistory();
  const [filter, setFilter] = useState(defaultFilter);
  const { shopOrigin  } = useShopProvider(); 
  const { boutiqAIConfig, boutiqAIConfigLoading } = useShopAgentConfig(shopOrigin);
  const featureEnabled = boutiqAIConfig?.enabled;

  const onChangeRange = (range) => {
    setFilter(prev => ({
      ...prev,
      range
    }))
  }

  const onRefresh = () => {
    setFilter({...filter});
  }

  return (
    <AppPage
      title={<>Boutiq <sup>AI</sup> Smart Agent</>}
      secondaryActions={[
        { content: 'Settings', icon: AdjustMinor, onAction: () => history.push('/boutiq-ai/agent/settings') },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Banner status="info" title={<>Boutiq <sup>AI</sup> Smart Agent beta</>}>
            <Text>Boutiq<sup>AI</sup> Smart Agent is available with no additional subscription fee during the beta program. (However, Boutiq commissions do apply.)</Text>
            <Text>At the end of the beta program this feature will require a paid subscription.</Text>
          </Banner>
          {!featureEnabled && !boutiqAIConfigLoading && <Banner status='warning' title="Boutiq AI Agent is disbaled">
            <Text>Boutiq<sup>AI</sup> Smart Agent is not enabled for your store.</Text>
            <Text>Go to <Link to='/boutiq-ai/agent/settings'>Settings</Link> to enable this feature.</Text>
          </Banner>}
        </Layout.Section>
        <Layout.Section>
        <Stack distribution='trailing'>
          <DateRangeSelector
            range={filter.range}
            onSelectRange={onChangeRange}
          />
          <Button icon={RefreshMajor} onClick={onRefresh}/>
        </Stack>
        </Layout.Section>
        <Layout.Section>
          <AgentDashboard filter={filter} />
        </Layout.Section>
        <Layout.Section>
          <AgentSessionList filter={filter} />
        </Layout.Section>
      </Layout>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <BoutiqAIAgent />
    </AdminPage>
  )
}
