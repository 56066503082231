import React, { useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Popover, Select, Stack, TextField, Checkbox, TextContainer, Collapsible } from '@shopify/polaris';

import { DateRange } from './useDateRange';
import Moment from 'moment-timezone';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { COMPARE_TYPES } from "../../constants/analytics";
import {
    FilterMinor,
} from '@shopify/polaris-icons';

import { HostSelector } from "../../components/Selectors/HostSelector";
import { EventTypesSelector } from "../../components/Selectors/EventTypeSelector";

export default function DashboardFilter({ shopEventTypesList, currentRange, onRangeUpdate, currentHost, shopUserTeams, setCurrentHost,
    hosts, setFilterType, filterType, currentEventType, setEventType }) {

    const [open, setOpen] = useState(false);
    const [range, setRange] = useState(currentRange);
    const [hostList, setHostList] = useState(currentHost ? [currentHost] : []);
    const [rangePopoverActive, setRangePopoverActive] = useState(false);
    const handleFilterMode = useCallback((newChecked, type) => setFilterType(newChecked ? type : 'all'), []);
    const [isApplyButtonDisabled, setButtonStatusDisabled] = useState(false);

    const ALL_HOSTS = { label: 'All hosts', value: 'all', id: 'all' };

    const handleOpenFilters = useCallback(() => setOpen((open) => !open), []);

    const setCompareHostFilter = (checked) => {
        handleFilterMode(checked, COMPARE_TYPES.HOSTS);
    }

    const setCompareEventTypeFilter = (checked) => {
        handleFilterMode(checked, COMPARE_TYPES.EVENT_TYPES);
    }

    if (hosts?.length > 0 && hostList?.length !== hosts?.length + 1) {
        setHostList([ALL_HOSTS]
            .concat(hosts.map(host => ({ label: `${host.firstName} ${host.lastName}`, value: host.id, id: host.id, teamId: host.teamId, automated: host.automated }))));
    }

    const togglePopoverActive = useCallback(() => {
        if ((!range?.start?.isValid() || !range?.end?.isValid()) && rangePopoverActive) {
            setRange(currentRange);
        }
        setRangePopoverActive((popoverActive) => !popoverActive)
    }, [rangePopoverActive, range]);

    const activator = (
        <Button onClick={togglePopoverActive} disclosure>
            {currentRange.name}
        </Button>
    );

    const selectRangeOptionsList = Object.keys(DateRange).map(key => ({ label: DateRange[key].name, value: key })).concat({ label: 'Custom', value: 'custom' })

    const onRangeChange = useCallback((id) => {
        if (id !== 'custom') {
            setRange(DateRange[id])
        } else {
            setRange({ id: 'custom', name: 'Custom', start: Moment().subtract(1, 'month'), end: Moment().endOf('day'), comparePeriod: 30, compareUnit: 'DAY' })
        }
    }, []);

    const onStartChange = (date) => {
        const startTime = Moment(date).startOf('day');
        setRange(currRange => ({ ...currRange, start: startTime, comparePeriod: currRange.end.diff(Moment(date), 'days') }));
        if ((startTime?.isAfter(range?.end) && !isApplyButtonDisabled) || !date) {
            setButtonStatusDisabled(true);
        } else if (startTime?.isBefore(range?.end) && isApplyButtonDisabled) {
            setButtonStatusDisabled(false);
        }
    }

    const onEndChange = (date) => {
        const endTime = Moment(date).endOf('day');
        setRange(currRange => ({ ...currRange, end: endTime, comparePeriod: Moment(date).endOf('day').diff(currRange.start, 'days') }));
        if ((range?.start?.isAfter(endTime) && !isApplyButtonDisabled) || !date) {
            setButtonStatusDisabled(true);
        } else if (range?.start?.isBefore(endTime) && isApplyButtonDisabled) {
            setButtonStatusDisabled(false);
        }
    }

    const onApply = () => {
        firebase.analytics().logEvent('admin.dashboard_filter_range', { range: range.id });
        onRangeUpdate(range);
        togglePopoverActive();
    }

    const updateEventType = (value) => {
        setEventType(value, filterType);
    }

    const renderAnalyticsFilters = () => {
        return (
            <Stack vertical={isMobile} distribution={!isMobile && 'trailing'}>
                {
                    hostList?.length > 0 &&
                    <Stack vertical={!isMobile} spacing={!isMobile && 'extraTight'} alignment={isMobile && 'center'}>
                        <HostSelector
                            hostsOptions={hostList}
                            value={currentHost}
                            shopUserTeams={shopUserTeams}
                            onChange={setCurrentHost} />

                        <Checkbox
                            label="compare hosts"
                            checked={filterType === COMPARE_TYPES.HOSTS}
                            onChange={setCompareHostFilter}
                        />                        
                    </Stack>
                }
                {
                    shopEventTypesList?.length > 0 &&
                    <Stack vertical={!isMobile} spacing={!isMobile && 'extraTight'} alignment={isMobile && 'center'}>
                        <EventTypesSelector
                            eventTypesOptions={shopEventTypesList}
                            value={currentEventType}
                            onChange={updateEventType} />
                        <Checkbox
                            label="compare event types"
                            checked={filterType === COMPARE_TYPES.EVENT_TYPES}
                            onChange={setCompareEventTypeFilter}
                        />
                    </Stack>
                }
                <Stack vertical={!isMobile} spacing={!isMobile && 'extraTight'} alignment={isMobile && 'center'}>
                    <Popover
                        active={rangePopoverActive}
                        activator={activator}
                        onClose={togglePopoverActive}
                        fluidContent={true}
                    >
                        <Popover.Pane fixed>
                            <div style={{ margin: '1rem ' }}>
                                <Stack vertical={true}>
                                    <Select
                                        label="Date range"
                                        options={selectRangeOptionsList}
                                        value={range.id}
                                        onChange={onRangeChange}
                                    />
                                    <Stack>
                                        <TextField
                                            label='Starting'
                                            value={range.start.format('YYYY-MM-DD')}
                                            type='date'
                                            onChange={onStartChange}
                                            disabled={range.id !== 'custom'}
                                            max={range.end.format('YYYY-MM-DD')}
                                        />

                                        <TextField
                                            label='Ending'
                                            value={range.end.format('YYYY-MM-DD')}
                                            type='date'
                                            onChange={onEndChange}
                                            disabled={range.id !== 'custom'}
                                            min={range.start.format('YYYY-MM-DD')}
                                        />
                                    </Stack>
                                    <Stack distribution='trailing'>
                                        <Button primary disabled={isApplyButtonDisabled} onClick={onApply}> Apply</Button>
                                    </Stack>
                                </Stack>
                            </div>
                        </Popover.Pane>
                    </Popover>
                    {
                        range.id === 'custom' &&
                        <TextContainer>
                            <p className='custom-time__label'>{`${currentRange.start.format('MM/DD/YYYY')} - ${currentRange.end.format('MM/DD/YYYY')}`}</p>
                        </TextContainer>
                    }
                </Stack>
            </Stack>
        )
    }

    return isMobile
        ? <Stack vertical>
            <Stack distribution='trailing'>
                <div style={{ marginRight: '16px' }}>
                    <Button
                        plain
                        onClick={handleOpenFilters}
                        icon={FilterMinor}
                    >
                        Filter
                    </Button>
                </div>
            </Stack>
            <Collapsible
                open={open}
                transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                expandOnPrint
            >
                {renderAnalyticsFilters()}
            </Collapsible>
        </Stack>
        : renderAnalyticsFilters();

}
