import React from 'react';
import {
    Avatar,
} from '@shopify/polaris';
import './styles.css';

export default function BoutiqAvatar({ source, name, initials, size }) {
    return (
        <div className="boutiq-avatar-container">
            <Avatar
                source={source}
                name={name}     
                initials={initials}
                size={size}
            />
        </div>
    )
}