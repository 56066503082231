import React from 'react';
import Moment from 'moment-timezone';
import {
    FlagMajor
} from '@shopify/polaris-icons';
import { currencyFormatter, pluralize } from './formatters';
import { Icon } from '@shopify/polaris';

export const AgentMessageRole = {
    user: 'user',
    boutiq: 'boutiq',
}

export const formatSessionTimestamp = (timestamp) => Moment(timestamp.toDate()).format('L LT');

export const formatSessionOrder = (sessionData) => (
    sessionData.totalOrderAmount
        ? currencyFormatter(sessionData.totalOrderAmountCurrency || sessionData.orders[0].currency, 2).format(sessionData.totalOrderAmount)
        : null
);

const sessionDataTableRow = ({
    sessionData,
    onRowClick = () => { },
}) => {
    const row = []

    const callAmountCell = () => {
        return formatSessionOrder(sessionData);
    }

    const clientDataCell = () => {
        const { customerName = "Anonymous Customer", customerEmail } = sessionData;
        return (
            <div>
                <p>{customerName}</p>
                <p>{customerEmail}</p>
            </div>
        )
    }

    const statsDataCell = () => {
        return (
            <div>
                <p>{sessionData.messagesCount ?? 0} {pluralize(sessionData.messagesCount ?? 0, 'Message')}</p>
                {sessionData.productsOfferedCount != null &&
                    <p>{sessionData.productsOfferedCount} {pluralize(sessionData.productsOfferedCount, 'Product')}</p>
                }
                {sessionData.events?.addToCartCount != null &&
                    <p>{sessionData.events.addToCartCount} {pluralize(sessionData.events.addToCartCount, 'Add to cart event')}</p>
                }
            </div>
        )
    }

    const onPush = (cell) => {
        row.push((
            <div onClick={() => onRowClick(sessionData)} style={{ cursor: 'pointer' }}>
                {cell}
            </div>
        ))
    }

    onPush(formatSessionTimestamp(sessionData.createdAt));
    onPush(clientDataCell())
    onPush(statsDataCell());
    onPush(<div>
        {sessionData.flagged
            ? <Icon
                source={FlagMajor}
                color='critical'
            />
            : ''}
    </div>)
    onPush(callAmountCell())

    return ({
        row,
        onPush,
    })
}

export default sessionDataTableRow
