import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

export default function useAIAgentSessionMessages(sessionId) {

  const ref = sessionId
    ? Firebase.firestore().collection('boutiqAIAgentSessions').doc(sessionId).collection('boutiqAIAgentSessionMessages').orderBy('timestamp', 'asc')
    : null;
  const [messages, messagesLoading, messagesError] = useCollection(ref);
  return { messages: messages?.docs ?? [], messagesLoading, messagesError }

}

