import moment from "moment-timezone";
import {COMPARE_TYPES} from "../constants/analytics";
import {ScheduleStatus} from "./consts";

export const numberFormatter = () =>
  new Intl.NumberFormat(window.navigator.language, {
  });

export const percentFormatter = () =>
  new Intl.NumberFormat(window.navigator.language, {
    style: 'percent',
    minimumFractionDigits: 1,
  });


export const currencyFormatter = (currencyCode, maximumFractionDigits = 1, notation = 'compact' ) =>
  new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: currencyCode,
    notation,
    compactDisplay: "short",
    minimumFractionDigits: 1,
    maximumFractionDigits,
  });

export const filterTimeFormatter = (time = '') => moment(time).format('YYYY-MM-DD');

export const utcStartDay = (date = moment()) => {
  return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
}

export const utcEndDay = (date = moment()) => {
  return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
}

export const updatedAtFormat = (date) => {
  return moment(date).format('MMM Do, h:mma');
}

export const getRandom = (arr, n) => {
  var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
  if (n > len)
      return arr;
  while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export const formatCounter = (value) => {
  if (value >= 1000) {
    return Intl.NumberFormat(window.navigator.language, {
      notation: "compact",
      maximumFractionDigits: 1
    }).format(value);
  }
  return value
}

export const formatUploadFilename = (filename) => `${Date.now()}_${filename.replace(/ /g,'_')}`;

export const initials = (displayName) => {
  if (!displayName) return '';
  return displayName.split(' ').filter(seg => !!seg).map(seg => seg[0]).slice(0, 2).join('').toUpperCase();
}

export const checkTodayTomorrow = (dateObject) => {
  let daySpec = new Intl.DateTimeFormat([], { weekday: 'long' }).format(dateObject);

  const today = new Date();
  if (today.toDateString() === dateObject.toDateString()) {
    daySpec = 'today';
  } else if (
    today.getFullYear() === dateObject.getFullYear() &&
    today.getMonth() === dateObject.getMonth() &&
    today.getDate() + 1 === dateObject.getDate()
  ) {
    daySpec = 'tomorrow';
  }

  return daySpec;
}

export const formatHostNameForCharts = (filterType, hosts = [], label) => {
  if (filterType === COMPARE_TYPES.HOSTS) {
    const host = hosts.find(host => host?.id === label);
    if (host) {
      return `${host?.firstName} ${host?.lastName}`;
    } else {
      return `*${label.substring(0,5)}...`;
    }
  }
  return label;
}

export const formatHostNameForTable = (hosts = [], hostId = null) => {
  if (!hostId || hosts?.length === 0) {
    return null;
  }
  const host = hosts.find(host => host?.id === hostId);
  if (!host?.firstName && !host?.lastName) {
    return hostId;
  }
  return `${host?.firstName} ${host?.lastName}`;
}

export function formatShortEventDate(date) {
  return new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(date);
}

export const statusMapper = (data) => {
  if (data.status === ScheduleStatus.deleted) {
    if (data.noShow)
      return 'no show'
    else
      return 'canceled'
  }
  return data.status;
}

export const pluralize = (count, singular, plural = `${singular}s`) => {
  const rules = new Intl.PluralRules('en-US');
  return rules.select(count) === 'one' ? singular : plural;
};
