import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BoutiqAIPage from './';
import RecordingDetails from './RecordingDetails';
import AgentPage from './Agent';
import SessionDetails from './Agent/SessionDetails';
import AgentSettings from './Agent/AgentSettings';
import AuthorizedAgentRoute from './AgentAuth';
function BoutiqAIRouter() {
  
  return (
    <Switch>
      <Route path='/boutiq-ai/call/:callId'>
        <RecordingDetails />
      </Route>

      <AuthorizedAgentRoute path='/boutiq-ai/agent/settings' redirectTo='/boutiq-ai'>
        <AgentSettings />
      </AuthorizedAgentRoute>
      <AuthorizedAgentRoute path='/boutiq-ai/agent/session/:sessionId' redirectTo='/boutiq-ai'>
        <SessionDetails />
      </AuthorizedAgentRoute>
      <AuthorizedAgentRoute path='/boutiq-ai/agent' redirectTo='/boutiq-ai'>
        <AgentPage />
      </AuthorizedAgentRoute>

      <Route path='/boutiq-ai'>
        <BoutiqAIPage />
      </Route>
    </Switch>
  );
}

export default BoutiqAIRouter;
