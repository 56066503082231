
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AppPage from '../../../../components/AppPage';
import AdminPage from '../../../../components/AdminPage';
import { useShopProvider } from '../../../../components/ShopProvider';
import { Layout, Card, Text, Stack, SkeletonBodyText, Link, Icon } from "@shopify/polaris";
import { useHistory } from 'react-router-dom';
import { useAISessionData } from '../../../../hooks/useAIAgentSessions';
import useAIAgentSessionMetadata from '../../../../hooks/useAIAgentSessionMetadata';
import { formatSessionTimestamp, formatSessionOrder } from '../../../../utils/agentSessions';
import { pluralize } from '../../../../utils/formatters';
import BoutiqAvatar from '../../../../components/Avatar';
import { FlagMajor } from '@shopify/polaris-icons';

import MessagesView from '../MessagesView';

const SessionDetails = () => {
  const history = useHistory();
  const { shopOrigin, hosts } = useShopProvider();
  const { sessionId } = useParams();
  const { sessionData, sessionDataLoading, sessionDataError } = useAISessionData(sessionId);
  const { metadata, metadataLoading, metadataError } = useAIAgentSessionMetadata(sessionId);
  const customerName = sessionData?.customerName ?? 'Anonymous Customer';

  const host = useMemo(() => hosts.find(h => h.id === sessionData?.host?.id), [hosts, sessionData]);
  const shopidyOrderUrl = useMemo(() => {
    const firstOrderId = sessionData?.orders ? Object.keys(sessionData.orders)[0] : null;
    if (!firstOrderId) return null;
    const [_, formatOrderId] = sessionData.orders[firstOrderId].orderId.split('-');
    return `https://${shopOrigin}/admin/orders/${formatOrderId}`;
  }, [sessionData, shopOrigin]);

  const customerNeeds = useMemo(() => {
    console.log('metadata', metadata);
    return metadata.find(m => m.id === 'customerNeeds')?.data()?.customerNeeds;
  }, [metadata]);

  return (
    <AppPage
      title={<>Boutiq <sup>AI</sup> Smart Agent session</>}
      breadcrumbs={[{ content: 'Back', onAction: () => history.goBack() }]}
    >
      <Layout>
        <Layout.Section>
          {sessionDataLoading && <Card><SkeletonBodyText /></Card>}
          {!sessionDataLoading &&
            <Card title="Session details" sectioned>
              <Card.Section>
                <Stack distribution='fillEvenly'>
                  <Stack vertical>
                    <Text fontWeight='semibold'>{formatSessionTimestamp(sessionData.createdAt)}</Text>
                    <Stack vertical spacing='extraTight'>
                      <Text>{sessionData.messagesCount} {pluralize(sessionData.messagesCount, 'Message')}</Text>
                      {sessionData.productsOfferedCount != null &&
                        <Text>{sessionData.productsOfferedCount} {pluralize(sessionData.productsOfferedCount, 'Product')}</Text>
                      }
                      {sessionData.events?.addToCartCount != null &&
                        <Text>{sessionData.events.addToCartCount} {pluralize(sessionData.events.addToCartCount, 'Add to cart event')}</Text>
                      }
                    </Stack>
                  </Stack>
                  {host && <Stack alignment="center">
                    <BoutiqAvatar
                      source={host?.avatarUrl ?? null}
                      name={host?.firstName}
                      initials={`${host?.firstName?.[0]}${host?.lastName?.[0]}`}
                    />
                    <Stack vertical>
                      <Text fontWeight="semibold">{host?.firstName} {host?.lastName}</Text>
                    </Stack>
                  </Stack>}
                </Stack>
              </Card.Section>
              <Card.Section>
                <Stack vertical>
                  <Stack vertical spacing='extraTight'>
                    <Text fontWeight='semibold'>{customerName}</Text>
                    {sessionData.customerEmail && <Text>{sessionData.customerEmail}</Text>}
                  </Stack>
                  {sessionData.totalOrderAmount && <Stack>
                    <Text fontWeight='semibold'>Order total: {formatSessionOrder(sessionData)}</Text>
                    <Link url={shopidyOrderUrl} external >View order details in Shopify</Link>
                  </Stack>}
                </Stack>
              </Card.Section>
            </Card>
          }
          {sessionData?.flagged && <Card
            title='Flagged'
            sectioned
          >
            {Object.entries(sessionData.flaggedReason).map(([key, reason]) => (
              <Card.Section key={key}>
                <Stack spacing='tight' wrap={false}>
                  <Icon source={FlagMajor} color='critical' />
                  <Text>{reason.reason}</Text>
                </Stack>
              </Card.Section>
            ))}
          </Card>}
          {customerNeeds && <Card title='Customer needs' sectioned >

            <Stack vertical>
              <Text>The customer needs and attributes as expressed in the chat session:</Text>
              {customerNeeds.map(need => (
                <Card.Section key={need.question} flush>
                  <Stack vertical spacing='extraTight'>
                    <Text fontWeight='semibold'>{need.question}</Text>
                    <Text>{need.answer}</Text>
                  </Stack>
                </Card.Section>
              ))}
            </Stack>
          </Card>}
          {sessionData && <MessagesView sessionId={sessionId} sessionData={sessionData} customerName={customerName} host={host} />}
        </Layout.Section>
      </Layout>
    </AppPage>
  )
}

export default function () {
  return (
    <AdminPage>
      <SessionDetails />
    </AdminPage>
  )
}
