import React from 'react';
import PropTypes from 'prop-types';
import {
	Stack,
	TextStyle,
	Pagination,
} from '@shopify/polaris'

function CallHistoryTablePagination({ currentPage, hasNext, itemsPerPage = 25, onChangePage, noun = 'calls' }) {

	const hasPrev = currentPage > 0;

	return (
		<Stack alignment='center' distribution='equalSpacing'>
			<Pagination
				hasPrevious={hasPrev}
				onPrevious={() => onChangePage(currentPage - 1)}
				hasNext={hasNext}
				onNext={() => onChangePage(currentPage + 1)}
			/>
			<Stack vertical alignment='trailing' spacing='extraTight'>
				<TextStyle variation='subdued'>
					{itemsPerPage} {noun} per page
        		</TextStyle>
			</Stack>
		</Stack>
	);
}

CallHistoryTablePagination.propTypes = {
	resultsCount: PropTypes.number,
	itemsPerPage: PropTypes.number,
	onChangePage: PropTypes.func,
};

export default CallHistoryTablePagination;
