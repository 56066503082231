import React from "react";
import { Select } from '@shopify/polaris';

export const SELECT_ALL_HOSTS_OPTION = { label: 'All Hosts', value: 'allHosts' };

export default function SelectHost({ hosts, selecetedHost, onHostChange, label = null, allowAll = true }) {
    const allowAllOption = allowAll ? [SELECT_ALL_HOSTS_OPTION] : [];
    const selectHostOptionsList = allowAllOption.concat(Object.keys(hosts)
            .map(key => ({ label: `${hosts[key].firstName} ${hosts[key].lastName}`, value: hosts[key].id, disabled: hosts[key].automated })));

    return (
        <Select
            label={label}
            options={selectHostOptionsList}
            value={selecetedHost}
            onChange={onHostChange}
        />);
}