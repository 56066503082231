import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Moment from 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/es';

import useScreenViews from "./hooks/useScreenViews";

import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import PolarisLinkComponent from './PolarisLinkComponent'

import ShopProvider from './components/ShopProvider';

import LoginPage from './pages/Login';
import SignUpPage from './pages/SignUp';
import SuperAdminPage from './pages/SuperAdmin';

import SettingsPage from './pages/Settings';
import ClientSettingsRouter from "./pages/Settings/ClientSettings/router";
import HostSettingsRouter from "./pages/Settings/HostSettings/router";


import IntegrationSettings from "./pages/Settings/IntegrationSettings";
import NotificationSettingsPage from "./pages/Settings/Notifications";
import BlacklistSettingsPage from "./pages/Settings/Blacklist";
import NotificationCustomTemplatePage from "./pages/Settings/Notification/CustomizeTemplate";
import NotificationCustomPage from './pages/Settings/Notification';
import StoreNotifications from './pages/Settings/StoreNotifications';
import BusinessSettingsPage from './pages/Settings/Business';
import CallPolicySettingsPage from './pages/Settings/CallPolicy';
import ProductsSettingsPage from './pages/Settings/Products';
import ChatSettingsPage from './pages/Settings/Chat';
import UsersSettingsPage from './pages/Settings/Users'
import HostScheduleSelection from './pages/Calendar/components/HostScheduleSelection';

import SchedulingSettingsPage from './pages/Settings/Scheduling';
import ServiceSettingsPage from './pages/Settings/Services';
import InviteHostPage from './pages/InviteHost';
import LandingPage from './pages/Landing';
import OnboardPage from './pages/Onboard';
import DashboardPage from './pages/Dashboard/Dashboard';
import SubscriptionPage from './pages/Subscription';
import SelectPlanPage from './pages/PricingPlans';
import CallHistory from './pages/CallHistory';
import ShowroomsPage from "./pages/Showrooms/ShowroomPresetsPage";
import ShowroomPresetDetailsPage from "./pages/Showrooms/ShowroomPresetDetailsPage";
import PromoCodePage from './pages/PromoCode';
import ShopLinksPage from './pages/ShopLinks';
import CampaignsPage from './pages/Campaigns';
import ShoppableStories from './pages/Stories/pages/ShoppableStories';
import StoryProductConfigPage from './pages/Stories/pages/StoryProductConfig'
import EditStoryPage from "./pages/Stories/pages/EditStory";
import CreateCampaignPage from './pages/Campaigns/createCampaign';
import CreateLinkPage from './pages/ShopLinks/createLink';
import EditLinkPage from './pages/ShopLinks/editLink';
import CalendarPage from './pages/Calendar';
import BoutiqAIRouter from './pages/BoutiqAI/router'; 
import RecordingSettingsPage from "./pages/BoutiqAI/RecordingSettings";
import HostCalendarPage from './pages/Calendar/hostCalendar';
import HostCalendarSettings from './pages/Calendar/hosts';
import EventTypeSettings from './pages/Calendar/eventTypes';
import EditEventType from './pages/Calendar/editEventType';
import CalendarAnalytics from './pages/Calendar/analytics';
import AppAuthStateProvider, { useAppAuthState } from './authState';
import './utils/facebookPixel';
import './App.css';
import { StoriesProvider } from './pages/Stories/components/StoryiesProvider';
import BoutiqStory from './pages/Stories/pages/BoutiqStory';
import StoriesAnalytics from './pages/Stories/pages/StoriesAnalytics';
import { CubeProvider } from './cubeManager';
import CallFeedback from './pages/CallFeedback';


// set the default locale to english (becasue we loaded other locales after init)
Moment.locale('en');

var shopOrigin = new URLSearchParams(window.location.search).get('shop');
if (shopOrigin) {
  localStorage.setItem('shopOrigin', shopOrigin);
} else {
  shopOrigin = localStorage.getItem('shopOrigin');
}

function AppPages() {
  useScreenViews(shopOrigin);
  const { adminRole } = useAppAuthState();
  return (
    <Switch>
      <Route path='/landing'>
        <LandingPage />
      </Route>
      <Route path='/login'>
        <LoginPage />
      </Route>
      <Route path='/signup'>
        <SignUpPage />
      </Route>
      <Route path='/superadminLogin'>
        <SuperAdminPage />
      </Route>
      <Route path='/'>
        <ShopProvider shopOrigin={adminRole && shopOrigin} adminRole={adminRole}>
          <CubeProvider>
            <Switch>
              <Route path='/onboard/:URLStep?'>
                <OnboardPage />
              </Route>
              <Route path='/inviteHost'>
                <InviteHostPage />
              </Route>
              <Route path='/usersSettings'>
                <UsersSettingsPage />
              </Route>
              <Route path='/hostSelectionSettings'>
                <HostScheduleSelection />
              </Route>
              <Route path='/settings'>
                <SettingsPage />
              </Route>
              <Route path='/call_history'>
                <CallHistory/>
              </Route>
              <Route path='/call_feedback'>
                <CallFeedback/>
              </Route>
              <Route path='/clientSettings'>
                <ClientSettingsRouter />
              </Route>
              <Route path='/hostSettings'>
                <HostSettingsRouter />
              </Route>
              <Route exact path='/notifications/customTemplate'>
                <NotificationCustomTemplatePage />
              </Route>
              <Route exact path='/notifications/storeNotifications'>
                <StoreNotifications />
              </Route>
              <Route path='/notifications'>
                <NotificationSettingsPage />
              </Route>
              <Route path='/banned'>
                <BlacklistSettingsPage />
              </Route>
              <Route path='/notification/:type'>
                <NotificationCustomPage />
              </Route>
              <Route path='/integrationSettings'>
                <IntegrationSettings />
              </Route>
              <Route path='/businessConfig'>
                <BusinessSettingsPage />
              </Route>
              <Route path='/callPolicyConfig'>
                <CallPolicySettingsPage />
              </Route>
              <Route path='/productsConfig'>
                <ProductsSettingsPage />
              </Route>
              <Route path='/chatConfig'>
                <ChatSettingsPage />
              </Route>
              <Route path='/schedulingAdvanced'>
                <SchedulingSettingsPage />
              </Route>
              <Route path='/subscription'>
                <SubscriptionPage />
              </Route>
              <Route path='/services'>
                <ServiceSettingsPage />
              </Route>
              <Route path='/select_plan'>
                <SelectPlanPage />
              </Route>
              <Route path='/promo_code'>
                <PromoCodePage />
              </Route>
              <Route path='/calendar/hosts/:hostId'>
                <HostCalendarPage />
              </Route>
              <Route path='/calendar/hosts'>
                <HostCalendarSettings />
              </Route>
              <Route path='/calendar/eventTypes/:id'>
                <EditEventType />
              </Route>
              <Route path='/calendar/eventTypes'>
                <EventTypeSettings />
              </Route>
              <Route path='/calendar/analytics'>
                <CalendarAnalytics />
              </Route>
              <Route path='/calendar'>
                <CalendarPage />
              </Route>
              <Route path='/shop_links/create'>
                <CreateLinkPage />
              </Route>
              <Route path='/shop_links/edit/:id'>
                <EditLinkPage />
              </Route>
              <Route path='/shop_links'>
                <ShopLinksPage />
              </Route>
              <Route path='/showrooms/:id'>
                <ShowroomPresetDetailsPage />
              </Route>
              <Route path='/showrooms'>
                <ShowroomsPage />
              </Route>
              <Route path='/campaigns/create'>
                <CreateCampaignPage />
              </Route>
              <Route path='/campaigns/edit/:id'>
                <CreateCampaignPage />
              </Route>
              <Route path='/campaigns'>
                <CampaignsPage />
              </Route>
              <Route path='/stories'>
                <StoriesProvider>
                  <Switch>
                    <Route path='/stories/sequence'>
                      <EditStoryPage />
                    </Route>
                    <Route path='/stories/config'>
                      <StoryProductConfigPage />
                    </Route>
                    <Route path='/stories/boutiq'>
                      <BoutiqStory />
                    </Route>
                    <Route path='/stories/shoppable'>
                      <ShoppableStories />
                    </Route>
                    <Route path='/stories/analytics'>
                      <StoriesAnalytics />
                    </Route>
                  </Switch>
                </StoriesProvider>
              </Route>
              <Route path='/boutiq-ai'>
                <BoutiqAIRouter />
              </Route>
              <Route path='/recording'>
                <RecordingSettingsPage />
              </Route>
              <Route exact path='/'>
                <DashboardPage />
              </Route>
              <Redirect to="/" />
            </Switch>
          </CubeProvider>
        </ShopProvider>
      </Route>
    </Switch>
  )
}

function App() {

  console.log('Project:', process.env.REACT_APP_FIREBASE_PROJECT_ID, 'Version:', process.env.REACT_APP_VERSION);

  // for backwards compatability redirect browser to
  if (window.location.hostname.includes('caazam')) {
    let newAdminUrl = `${process.env.REACT_APP_CAAZAM_ADMIN_APP}?shop=${shopOrigin}`;
    return (<p>Redirecting to {window.location = newAdminUrl}</p>)
  }

  return (
    <AppProvider
      i18n={enTranslations}
      linkComponent={PolarisLinkComponent}
    >
      <AppAuthStateProvider shopOrigin={shopOrigin}>
        <BrowserRouter basename='/admin'>
          <AppPages />
        </BrowserRouter>
      </AppAuthStateProvider>
    </AppProvider>
  )
}

export default App;
