import { useEffect, useState, useCallback } from 'react';
import {
	getFirestore,
	getAggregateFromServer,
	collection,
	where,
	orderBy,
	startAt,
	limit,
	doc,
	getDocs,
	query,
	count,
	sum,
	getCountFromServer
} from 'firebase/firestore';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { getPreviousPeriod } from '../utils/time';

const getQuery = (shopId, range, cursor) => {
	const firestore = getFirestore();
	let q = collection(firestore, 'boutiqAIAgentSessions');
	q = query(q, where('shopId', '==', shopId));

	if (range) {
		const start = range.start.set({ hour: 0, minutes: 0, seconds: 0 }).toDate()
		const end = range.end.set({ hour: 23, minutes: 59, seconds: 59 }).toDate()
		q = query(q, where('createdAt', '<=', end));
		q = query(q, where('createdAt', '>=', start));
	}
	q = query(q, orderBy('createdAt', 'desc'));

	if (cursor) {
		q = query(q, startAt(cursor));
	}
	return q;
}

export default function useAIAgentSessions(shopId, statusOptions, rowsCount, filter) {
	const [sessions, setSessions] = useState([]);
	const [loading, setLoading] = useState(false)
	const [getNextSessions, setNextSessions] = useState(null);
	const { range } = filter;

	const getSessions = async (shopId, statusOptions, doclimit, cursor = null) => {
		setLoading(true)
		const q = getQuery(shopId, range, cursor);
		const querySnap = await getDocs(query(q, limit(doclimit + 1)));
		let docs = querySnap.docs;
		let next = null;
		if (querySnap.size > doclimit) {
			const nextCursor = docs.pop();
			next = () => () => getSessions(shopId, statusOptions, doclimit, nextCursor);
		}
		return { docs, next };
	}

	useEffect(() => {
		getSessions(shopId, statusOptions, rowsCount * 2, null).then(({ docs, next }) => {
			setSessions([...sessions, ...docs]);
			setNextSessions(next);
			setLoading(false)
		});
	}, [])

	const refresh = () => {
		getSessions(shopId, statusOptions, rowsCount * 2, null).then(({ docs, next }) => {
			setSessions([...docs]);
			setNextSessions(next);
			setLoading(false)
		});
	};

	const getMoreSessions = () => {
		if (getNextSessions) {
			getNextSessions().then(({ docs, next }) => {
				setSessions([...sessions, ...docs]);
				setNextSessions(next);
			});
		}
	}

	return {
		sessions,
		getMoreSessions,
		refresh,
		loading
	};
}

export function useAISessionData(sessionId) {
	const firestore = getFirestore();
	const ref = sessionId ? doc(firestore, 'boutiqAIAgentSessions', sessionId) : null;
	const [sessionData, sessionDataLoading, sessionDataError] = useDocumentDataOnce(ref);
	return {
		sessionData, sessionDataLoading, sessionDataError,
	}
}

export function useAIAgentSessionAggregates(shopId, filter) {
	const [aggregates, setAggregates] = useState(null);
	const [loading, setLoading] = useState(false)
	const getSessionsAggregates = async () => {
		setLoading(true)
		try {
			const previousPeriod = getPreviousPeriod(filter.range.start, filter.range.end);
			const q = getQuery(shopId, filter.range, null);
			const previousQ = getQuery(shopId, previousPeriod, null);

			const totalSessions = (await getCountFromServer(q)).data().count;
			const agg = await getAggregateFromServer(q,
				{
					totalOrders: count('totalOrderAmount'),
					totalOrderAmount: sum('totalOrderAmount')		  // TODO: does not take currency diff into account		
				}
			);
			const totalSessionsPrevious = (await getCountFromServer(previousQ)).data().count;
			const previousAgg = await getAggregateFromServer(previousQ,
				{
					totalOrdersPrevious: count('totalOrderAmount'),
					totalOrderAmountPrevious: sum('totalOrderAmount')		  // TODO: does not take currency diff into account		
				}
			);
			return {
				totalSessions,
				...agg.data(),
				totalSessionsPrevious,
				...previousAgg.data()
			}
		} catch (e) {
			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getSessionsAggregates().then(setAggregates)
	}, [shopId, filter]);

	return {
		aggregates, loading
	}

}